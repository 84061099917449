.nav-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.nav-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
}

.title {
  font-size: calc(10px + 6vmin);
  color: black;
  margin-bottom: 0;
}

.sub-title {
  font-size: calc(10px + 1vmin);
  color: black;
  font-weight: 300;
}

ul {
  display: inline-block;
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
}

@media only screen and (min-width: 500px) {
  ul > li {
    display: inline-block;
  }
}

li a {
  display: block;
  color: black;
  text-align: center;
  width: 80px;
  margin: 16px;
  text-decoration: none;
  font-size: 1em;
}

li a:hover {
  cursor: pointer;
  font-weight: bold;
}

.section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section > div:first-of-type {
  width: 80vw;
  max-width: 800px;
}

.video-wrapper {
	height: 50vw;
  margin-top: 2vh;
  margin-bottom: 2vh;
	max-height: 450px;
}

.social-links-wrapper {
  display: flex;
  justify-content: center;
  flex-direction: row;
  margin-top: 40px;
}

.social-links-wrapper > div {
  width: 20px;
}

.social-links-wrapper > svg {
	display: block;
  width: 80px;
  margin: 16px;
}

.social-links-wrapper > svg:hover {
  cursor: pointer;
}

.contact-section-image {
	max-width: 300px;
	margin-top: 30px;
  position: relative;
  bottom: -4px;
}

.about-section-image {
	width: 100%;
	margin-top: 30px;
	margin-bottom: 30px;
}