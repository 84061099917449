.app {
  text-align: center;
  padding: 5vw;
  padding-bottom: 0;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0.8)
    ),
    url("../resources/background.jpg");
  background-position: center;
  background-size: contain;
}
